
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api'
import { useImage } from '~/composables'
import { KNOWN_PLACEHOLDER_PATTERNS } from '~/helpers/images/patterns'

export default defineComponent({
  props: {
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    zoom: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: undefined
    },
    height: {
      type: Number,
      default: undefined
    },
    fit: {
      type: String,
      default: 'cover'
    },
    preload: {
      type: Boolean,
      default: false
    },
    sizes: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { getMagentoImage } = useImage()
    const transformOrigin = ref<string>('center')
    const transform = ref<string>('scale(1)')
    const hasZoomStarted = ref<boolean>(false)
    const loadingError = ref(false)
    const isPlaceholder = computed<boolean>(() => loadingError.value || KNOWN_PLACEHOLDER_PATTERNS.some(regex => regex.test(props.image)))

    const usedImage = computed(() => {
      if (!props.image || isPlaceholder.value) {
        return getMagentoImage('/productpage/placeholder.webp')
      }

      return getMagentoImage(props.image)
    })

    function leave (): void {
      transformOrigin.value = 'center'
      transform.value = 'scale(1)'
    }

    function move (e): void {
      hasZoomStarted.value = true
      const size = e.target.getBoundingClientRect()
      const { x, y, width, height } = size
      const clientX: number = (e.clientX - x) / width * 100
      const clientY: number = (e.clientY - y) / height * 100

      transformOrigin.value = `${clientX}% ${clientY}%`
      transform.value = 'scale(2)'
    }

    function onImageLoadingError (event): void {
      console.error('Image loading error', event)
      loadingError.value = true
    }

    watch(() => props.image, (newValue: string, oldValue: string) => {
      if (newValue !== oldValue) {
        loadingError.value = false
      }
    })

    return {
      transform,
      transformOrigin,
      hasZoomStarted,
      usedImage,
      isPlaceholder,
      loadingError,
      leave,
      move,
      getMagentoImage,
      onImageLoadingError
    }
  }
})
