import { defineStore } from 'pinia'
import { ref } from '@nuxtjs/composition-api'
import { UseProductAnalyticsStore } from '~/modules/catalog/aatrium/product/types/UseProductAnalyticsStore'

export const useProductAnalyticsStore = defineStore('product-analytics', (): UseProductAnalyticsStore => {
  const productId = ref<number>(undefined)

  function setProductId (id: number): void {
    productId.value = id
  }

  return {
    productId,
    setProductId
  }
})
