<template functional>
  <span
    :class="[data.class, data.staticClass, 'badge']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </span>
</template>
<script>
export default {
  name: "Badge",
};
</script>
<style lang="scss">
.badge {
  display: inline-block;
  box-sizing: border-box;
  width: var(--badge-width);
  min-width: var(--badge-min-width);
  height: var(--badge-height);
  min-height: var(--badge-min-height);
  padding: var(--badge-padding, var(--spacer-xs) var(--spacer-sm));
  background: var(--badge-background, var(--c-primary));
  border: var(--badge-border);
  border-radius: var(--badge-border-radius);
  color: var(--badge-color, var(--c-white));
  text-align: var(--badge-text-align, center);
  font-size: var(--badge-font-size, 16px);
  font-weight: var(--badge-font-weight, 500);
  line-height: var(--badge-font-line-height, 500);
}
</style>
