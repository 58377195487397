
import { computed, PropType } from '@nuxtjs/composition-api'
import BaseButton from '../BaseButton.vue'
import { useUiState, useWishlist, useUser } from '~/composables'
import { GtagProductAnalyticsData } from '~/modules/aatrium-gtm'

export default {
  name: 'AddToWishList',
  components: { BaseButton },
  props: {
    product: {
      type: Object,
      default: null
    },
    analyticData: {
      type: Object as PropType<GtagProductAnalyticsData>,
      required: false,
      default: () => ({})
    }
  },
  setup (props) {
    const { addItem, isInWishlist, removeItem } = useWishlist()
    const { toggleWishlistSidebar } = useUiState()
    const { isAuthenticated } = useUser()

    const alreadyAdded = computed(() => isInWishlist(props.product))

    async function addItemToWishlist () {
      if (!isAuthenticated.value) {
        toggleWishlistSidebar()

        return
      }

      if (alreadyAdded.value) {
        await removeItem({ product: props.product })
        return
      }

      await addItem({ product: props.product }, props.analyticData)
    }

    return { alreadyAdded, addItemToWishlist }
  }
}
