import { ProductsType } from '../variables/products'
import { AatriumProductGetters } from '~/modules/catalog/aatrium/getters/interfaces/AatriumProductGetters'
import { AatriumProduct, SpecialPriceTier } from '~/modules/catalog/types'
import { getProductSku, getSlug } from '~/modules/catalog/product/getters/productGetters'
import { MediaGalleryInterface, UrlRewrite } from '~/modules/GraphQL/types'

export function getImageByPosition (product: {media_gallery?: MediaGalleryInterface[]}, position: number): string | undefined {
  const { media_gallery: mediaGallery } = product

  if (!mediaGallery?.length) {
    return undefined
  }

  const image = mediaGallery.find(image => image.position === position) ?? mediaGallery?.[position - 1]
  return image?.url
}

export function isAddableToWishlist (product: { __typename: ProductsType | string }): boolean {
  return product.__typename !== ProductsType.GiftCard
}

export function getLoyaltyPrice (product: { available_price_tiers?: SpecialPriceTier[] }): number {
  const { available_price_tiers: availableTiers } = product

  if (availableTiers?.length) {
    const [{ final_price: loyaltyPrice }] = availableTiers

    if (loyaltyPrice?.value) {
      return loyaltyPrice.value
    }
  }

  return 0
}

export function getLink (product: AatriumProduct): string {
  return `/p/${getProductSku(product)}${getSlug(product).replace('/p/', '/')}`
}

export function isVirtualProduct (product: AatriumProduct): boolean {
  return product?.__typename === 'VirtualProduct' || false
}

export function getUrlRewrites (product: AatriumProduct): UrlRewrite[] {
  return product?.url_rewrites || []
}

export function isGiftCardProduct (product: {__typename: string}): boolean {
  return product?.__typename === ProductsType.GiftCard ?? false
}

const aatriumProductGetters: AatriumProductGetters = {
  getImageByPosition,
  getLoyaltyPrice,
  isAddableToWishlist,
  getLink,
  isVirtualProduct,
  getUrlRewrites,
  isGiftCardProduct
}

// eslint-disable-next-line import/no-default-export
export default aatriumProductGetters
