
import { computed, PropType, defineComponent } from '@nuxtjs/composition-api'
import { getPrice } from '~/modules/catalog/product/getters/productGetters'
import { ProductBadge } from '~/components/ProductBadges/types'
import Badge from '~/components/atoms/Badge.vue'
import { ProductInterface } from '~/modules/GraphQL/types'
import { ProductShort } from '~/types/product'

export default defineComponent({
  name: 'ProductBadges',
  components: { Badge },
  props: {
    product: {
      type: Object as PropType<ProductInterface | ProductShort>,
      required: true
    }
  },
  setup (props) {
    const productPrice = computed(() => getPrice(props.product))

    const badges = computed<ProductBadge[]>(() => {
      const badges: ProductBadge[] = []

      if (productPrice.value.special) {
        const regularPrice = productPrice.value.regular
        const specialPrice = productPrice.value.special
        const discountPercentage = Math.round(100 - (specialPrice * 100 / regularPrice))
        const formatted = `-${discountPercentage}%`

        badges.push({ type: 'default', value: formatted })
      }

      if (props.product?.new_to_date) {
        const currentDate = new Date()
        const newToDate = new Date(props.product.new_to_date)

        if (newToDate > currentDate) {
          badges.push({ type: 'new', value: 'new' })
        }
      }

      return badges
    })

    return {
      badges
    }
  }
})
