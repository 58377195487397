
export default {
  name: 'ProductPrice',
  props: {
    regular: {
      type: Number,
      default: 0
    },
    special: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: null
    }
  }
}
