import { computed } from '@nuxtjs/composition-api'
import { isEmpty } from 'lodash-es'
import {
  getTotalStoreAvailability,
  isBackorderAvailable as getBackorderAvailability,
  isInShowroomAvailable,
  isInStock,
  isOrderMoreAvailable,
  getAvailableQuantity,
  getAvailabilityInShowroom
} from '~/modules/catalog/product/getters/productGetters'
import { AatriumProduct } from '~/modules/catalog/types'
import { UseProductAvailability } from '~/modules/catalog/aatrium/product/composables/useProductAvailability/types'

export function useProductAvailability (product: AatriumProduct): UseProductAvailability {
  const isShowroomAvailable = computed<boolean>(() => !isEmpty(product) && isInShowroomAvailable(product))
  const orderMoreAvailable = computed<string | false>(() => !isEmpty(product) && isOrderMoreAvailable(product))
  const isBackorderAvailable = computed<boolean>(() => !isEmpty(product) && getBackorderAvailability(product))
  const totalAvailabilityInShowroom = computed<number>(() => !isEmpty(product) && getAvailabilityInShowroom(product))
  const totalStoreAvailability = computed<number | string>(() => {
    const totalQty = getTotalStoreAvailability(product)
    return totalQty > 10 ? '>10' : totalQty
  })
  const isProductAvailable = computed<boolean>(() => (
    !isEmpty(product) && isInStock(product) && !!totalStoreAvailability.value
  ))
  const isLastAvailableProduct = computed<boolean>(() => (
    !isEmpty(product) && getAvailableQuantity(product) === 1
  ))

  return {
    isShowroomAvailable,
    orderMoreAvailable,
    totalStoreAvailability,
    isProductAvailable,
    isBackorderAvailable,
    isLastAvailableProduct,
    totalAvailabilityInShowroom
  }
}
